<template>
  <div v-click-outside="modalClosed">
    <toggle-button v-if="!otp_activated" data-testid="otp_active_toggle_on" data-bs-toggle="modal"
                   data-bs-target="#otpSetupModal" v-model="otp_activated" :labels="switchLabels" :color="switchColors"
                   width="55px" height="25px"/>
    <toggle-button v-if="otp_activated" data-testid="otp_active_toggle_off" data-bs-toggle="modal"
                   data-bs-target="#otpDisableModal" v-model="otp_activated" :labels="switchLabels" :color="switchColors"
                   width="55px" height="25px"/>
  </div>
</template>
<script>
import ToggleButton from "./toggle-button.vue"
import EventBus from "../src/event_bus";

export default {
  props: {
    otp_active: Boolean,
  },

  mounted() {
    this.resetButtonState();
  },

  data() {
    return {
      otp_activated: false,
      switchLabels: {
        checked: "ON",
        unchecked: "OFF"
      },
      switchColors: {
        checked: "#8CE4BF",
        disabled: "#8CE4BF",
        unchecked: "#454545",
      },
    }
  },
  methods: {
    resetButtonState() {
      this.otp_activated = this.otp_active;
    },
    modalClosed() {
      let setup_modal = $("#otpSetupModal");
      let disable_modal = $("#otpDisableModal");
      if (!setup_modal.hasClass("show") && !disable_modal.hasClass("show")) {
        this.resetButtonState();
        EventBus["modal-closed"]()
      }
    }
  },
  components: {ToggleButton}
}
</script>
<style lang="scss" scoped>
.vue-js-switch.disabled {
  opacity: 0.9;
}
</style>
