<template>
  <div>
    <div
      class="modal fade" id="otpSetupModal" role="dialog" aria-hidden="true" ref="otpsetupmodal">
      <div class="modal-dialog" role="document" style="max-width: 700px;">
        <div class="modal-content" style="min-height: 500px" id="otpSetupPrintable">
          <div class="modal-body p-0">
            <div class="row bg-light p-3 m-0 text-center rounded-top">
              <div class="col p-0 font-weight-bold" v-bind:class="{ 'text-primary': setupStep === 1}">
                <div class="circle-background text-white d-inline-flex text-center"
                     v-bind:class="[ setupStep === 1 ? 'bg-primary' : 'bg-dark']">
                  <div class="mx-auto">1</div>
                </div>
                <span class="ms-1">{{ t.setup_app }}</span>
              </div>
              <div class="col p-0 font-weight-bold" v-bind:class="{ 'text-primary': setupStep === 2}">
                <div class="circle-background text-white d-inline-flex text-center"
                     v-bind:class="[ setupStep === 2 ? 'bg-primary' : 'bg-dark']">
                  <div class="mx-auto">2</div>
                </div>
                <span class="ms-1">{{ t.enter_code }}</span>
              </div>
              <div class="col p-0 font-weight-bold" v-bind:class="{ 'text-primary': setupStep === 3}">
                <div class="circle-background text-white d-inline-flex text-center"
                     v-bind:class="[ setupStep === 3 ? 'bg-primary' : 'bg-dark']">
                  <div class="mx-auto">3</div>
                </div>
                <span class="ms-1">{{ t.backup_codes }}</span>
              </div>
              <div class="col-1 p-0 float-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"></span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body" id="printBorder">
            <div class="row px-1 px-md-5">
              <div class="col col-md-auto">
                <transition name="slide-fade">
                  <div v-if="setupStep === 1" class="container text-center mt-4">
                    <p>
                      {{ t.when_you_login }}
                    </p>
                    <p>
                      {{ t.download_authenticator_app }}
                    </p>
                    <p><strong>{{ t.scan_with_auth_app }}:</strong></p>
                    <div class="img-fluid" v-html="qr_code_image"></div>
                    <div class="my-4">
                      <div class="row text-center">
                        <div class="col">
                          <button v-on:click="setupStep = 2" type="button" class="btn btn-primary">{{
                              t.continue
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div v-if="setupStep === 2" class="container text-center p-0 p-md-4">
                    <h4>{{ t.enter_code_from_auth_app }}</h4>
                    <p>
                      {{ t.enter_digit_code }}
                    </p>
                    <div class="row">
                      <div class="col-12 col-md-6 mx-auto">
                        <form v-on:submit.prevent="regenerateBackupCodes">
                          <div class="mb-4">
                            <input v-model="token" :placeholder="t.otp_placeholder" class="form-control mt-2"
                                   autocomplete="one-time-code" inputmode="numeric" pattern="[0-9]*" required="true">
                            <button class="btn btn-primary px-5 my-4 w-100">
                              {{ t.continue }}
                            </button>
                          </div>
                          <div class="row mx-0" v-if="errorMessage">
                            <div class="my-1" style="width: 100%;">
                              <div class="template-alert alert-danger">
                                {{ errorMessage }}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div v-if="setupStep === 3" class="container text-center">
                    <h3>{{ t.save_backup_codes }}</h3>
                    <p>
                      {{ t.case_no_access }}
                    </p>
                    <div class="container border border-dark">
                      <div class="row">
                        <div class="col">Kaleido Account</div>
                      </div>
                      <div class="row">
                        <div class="col">{{ email }}</div>
                      </div>
                      <div class="row">
                        <div v-for="code in backupCodes" class="col-6 p-2 backup-code">
                          {{ code }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col mb-4">
                          <small>{{ t.use_backup_code_once }}</small><br>
                          <small>{{ t.codes_generated_on }} {{ generationDate }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col">
                        <a href="#" class="hideOnPrint" v-on:click="printCodes()">{{ t.print }}</a>
                      </div>
                    </div>
                    <form v-on:submit.prevent="enableOTP">
                      <div class="row">
                        <div class="col hideOnPrint">
                          <input type="checkbox" name="backup-saved" id="backup-saved" required="true"
                                 oninvalid="this.setCustomValidity(t.confirm_print)" oninput="setCustomValidity('')">
                          <label for="backup-saved">
                            {{ t.saved_or_printed_codes }}*
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col hideOnPrint">
                          <input type="submit" class="btn btn-primary mb-1 mt-3" :value="t.finish">
                        </div>
                      </div>
                    </form>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "../src/event_bus";

export default {
  props: {
    provisioning_uri: String,
    qr_code_image: String,
    regenerate_backup_url: String,
    enable_otp_url: String,
    user_profile_url: String,
    email: String,
    t: Object,
  },

  data() {
    return {
      show: true,
      setupStep: 1,
      errorMessage: undefined,
      token: undefined,
      backupCodes: undefined,
      backupSaved: undefined,
      generationDate: undefined
    }
  },
  mounted() {
    EventBus["modal-closed"] = () => {
      this.resetWizard();
    }
  },
  methods: {
    resetWizard() {
      this.errorMessage = undefined;
      this.setupStep = 1;
    },

    regenerateBackupCodes() {
      this.errorMessage = undefined;
      $.ajax({
        method: "POST",
        url: this.regenerate_backup_url,
        data: {
          user: {
            otp_attempt: this.token,
          }
        }
      }).then((response) => {
        if (response.result === false) {
          this.errorMessage = this.t.token_invalid
        } else {
          this.backupCodes = response.result
          this.setupStep += 1;
          this.generationDate = new Date().toDateString();
        }

      })

    },
    enableOTP() {
      $.ajax({
        method: "POST",
        url: this.enable_otp_url,
      }).then((response) => {
        if (response.result === true) {
          window.location.href = this.user_profile_url
        }
      })
    },
    printCodes() {
      let elem = document.getElementById("printBorder")
      let domClone = elem.cloneNode(true);
      var $printSection = document.getElementById("printSection");

      if (!$printSection) {
        var $printSection = document.createElement("div");
        $printSection.id = "printSection";
        document.body.appendChild($printSection);
      }
      $printSection.innerHTML = "";
      $printSection.appendChild(domClone);
      window.print();
    },
  },
}
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .8s ease;

}

.slide-fade-leave-active {

  display: none;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0.0;
}

.backup-code {
  font-size: 120%;
}

.circle-background {
  border-radius: 50%;
  position: relative;
  width: 25px;
  height: 25px;
}
</style>
